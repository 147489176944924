export const adresseGetCustomerId = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/getCustomerId'
export const adresseSubStatus = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/sub-status'
export const adresseHgg = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/double-hgg'
export const adresseEsh = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/double-esh'
export const adresseCg = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/double-cg'
export const adresseCreateCheckoutSession = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/create-checkout-session'
export const adressseCheckPaymentStatus = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/check-payment-status'
export const adresseCustomerPortal = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/customer-portal'
export const stripeKey = 'pk_live_51OwPy8D35M2RKGuK6Ve37fX1wNH124OlYV05bY17Vzmz3Ie8HnyGlAUcXyWrUygkZAjWH0tOSvkgmuQBNGIUZDJ100n4IKm85m'
export const firebaseConfig = {
    apiKey: "AIzaSyBl9vgNWLDW6PRQ-VI3STX4zVV7tNlZtiI",
    authDomain: "moncolleur.firebaseapp.com",
    projectId: "moncolleur",
    storageBucket: "moncolleur.appspot.com",
    messagingSenderId: "227643407079",
    appId: "1:227643407079:web:4c35d85ee2fcfd3e65c042",
    measurementId: "G-01F5RBVT0J"
  };
  
//   test
// export const firebaseConfig = {
//     apiKey: "AIzaSyBi4ZHaIKK_F2eMw1JtM3liVvMv2ON7Occ",
//     authDomain: "moncolleur-test.firebaseapp.com",
//     projectId: "moncolleur-test",
//     storageBucket: "moncolleur-test.appspot.com",
//     messagingSenderId: "773851658450",
//     appId: "1:773851658450:web:e888dd94c8fc23cc4f550b",
//     measurementId: "G-5QZJFNP069"
//   };
// export const adresseHgg = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/test-cg'
// export const adresseEsh = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/test-cg'
// export const adresseCg = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/test-cg'
// export const adresseSubStatus = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/sub-status-test'
// export const adresseGetCustomerId = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/getCustomerId-test'
// export const adresseCreateCheckoutSession = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/create-checkout-session-test'
// export const adressseCheckPaymentStatus = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/check-payment-status-test'
// export const adresseCustomerPortal = 'https://europe-west9-ai-kholler-409719.cloudfunctions.net/customer-portal-test'
// export const stripeKey = 'pk_test_51OwPy8D35M2RKGuKnwIf51ejBGxmqOroIX1g17m7VUzfU0XSELnNWClr99CTQI0P0Z2l2CJaryrfMFjQnFgwuC54005Hs2vdIo'