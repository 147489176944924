import './colles.styles.scss';
import './colles.repsonsive.scss'
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../3-context/0-user.context';
import { PaymentContext } from '../../3-context/2-payment.context';

import { getInfo, getJsonPublicColle, getJsonColles } from '../../4-utils/firebase.utils';

import NotSub from '../../1-components/10-not-sub/not-sub.component';

const Colles = () => {
  const { currentUser } = useContext(UserContext);
  const { isSub, loadingContent } = useContext(PaymentContext);

  const [publicColle, setPublicColle] = useState(null);
  const [colles, setColles] = useState([]);
  const [prepaName, setPrepaName] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    const fetchColles = async () => {

      if (currentUser) {
        setIsLoading(true);
     
        // Colles publique
        const publicCollesData = await getJsonPublicColle();
        setPublicColle(publicCollesData);

        // Colles (toutes matières)
        const collesData = await getJsonColles(currentUser.uid);
        setColles(collesData);

        setIsLoading(false);
      }
    };

    fetchColles();
  }, [currentUser]);


  const renderColles = (matiere) => {

  const filteredColles = colles.filter(colle => colle.matiere === matiere);

  if (filteredColles.length === 0) {
    return null; // Ne rien rendre si aucune colle n'existe pour la matière
  }

  return (
    <Fragment>
      <div className='colles-subtitle'>{matiere}</div>
      {filteredColles.map((colle, index) => (
        <Link key={index} to={'/pdf'} onClick={() => sessionStorage.setItem('requestContent',JSON.stringify(colle))} className='colle'>
          <div className='cadre'>
            <div className={`title ${colle.methode === "plan" && "plan"}`}>
              {colle.data["user_subject"]}
            </div>
            {
              colle.methode === "plan" ? (
                <div className='content'>Plan détaillé</div>
              ) : (
                <div className='content'>{colle.data["note"]}/20</div>
              )
            }
          </div>
        </Link>
      ))}
    </Fragment>
  );
};

return (
  <Fragment>
    <div className='colles-container'>
      {loadingContent ? (
        <div className='loading-content'>Le contenu est en cours de chargement...</div>
      ) : (
        <Fragment>
          <div className='colles-title'>Mes Colles</div>
          {colles.length === 0 && (
            <div className='colles-subcontainer'>
              {publicColle && publicColle.length > 0 && <div className='colles-subtitle'>Démonstration</div>}
              {publicColle && publicColle.map((colle, index) => (
                <Link key={index} to={'/pdf'} onClick={() => sessionStorage.setItem('requestContent', JSON.stringify(colle))} className='colle'>
                  <div className='cadre'>
                    <div className='title'>
                      {colle.data["user_subject"]}
                    </div>
                    <div className='content'>{colle.data["note"]}/20</div>
                  </div>
                </Link>
              ))}
            </div>
          )}
          {isLoading ? (
            <div className='loading'>En cours de chargement...</div>
          ) : (
            <div className='colles-subcontainer'>
              {renderColles('HGG')}
              {renderColles('ESH')}
              {renderColles('CG')}
            </div>
          )}
        </Fragment>
      )}
    </div>
  </Fragment>
);
};

export default Colles;