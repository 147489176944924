import './sujet.styles.scss'

import { Fragment, useContext, useEffect, useState } from 'react';
import { PaymentContext } from '../../3-context/2-payment.context';
import { AnalyseContext } from '../../3-context/3-analyse.context';
import { SelectContext } from '../../3-context/5-select.context';

import CustomSelect from '../7-element-select/element-select.component';
import NotSub from '../10-not-sub/not-sub.component';

import sujet_hgg from '../../0-data/sujets_hgg.json'
import sujet_esh from '../../0-data/sujets_esh.json'
import sujet_cg from '../../0-data/sujets_cg.json'
import sujet_cg_oraux from '../../0-data/sujets_cg_oraux.json'
import { listeSujetHGG, listeSujetESH, listeSujetAm, listeSujetAs, listeSujetEu } from './sujet.data';


const Sujet = ({matiere}) => {
    const { setSubject, subject, special } = useContext(AnalyseContext)
    const { selectSubject, setSelectSubject } = useContext(SelectContext)
    const { isSub } = useContext(PaymentContext)

    const [annee, setAnnee] = useState(null)
    const [surTheme, setSurTheme] = useState(null)
    
    const handleSubmit = () => {
        if(selectSubject) 
        {
            let themePath
            if (annee) {
                themePath = surTheme ? sujet_hgg[`${annee}A`][surTheme][selectSubject] : sujet_hgg[`${annee}A`][selectSubject]
            }
            else
            {
                themePath = sujet_esh[selectSubject]
            } 
            const questionKeys = Object.keys(themePath);
            const randomIndex = Math.floor(Math.random() * questionKeys.length) + 1;
            setSubject(themePath[randomIndex])
        }
    }
    useEffect(() => {
        handleSubmit();
    }, [selectSubject]);

    // fais a part car pas de bouton intermediaire
    if (special) 
    {
        let randomIndex;
        const subject = matiere === "HGG" ? sujet_hgg : matiere === "ESH" ? sujet_esh : sujet_cg_oraux
       
        randomIndex = Math.floor(Math.random() * Object.keys(subject["Oraux 2024"]).length);
        setSubject(subject["Oraux 2024"][randomIndex])
        
    }
    else if (matiere === "CG")
    {
        if (isSub) 
        {
            // pour savoir combien ya de sujet et donc de generer un nb aleatoire selon le nb de sujet 
            const cg_subject = Object.keys(sujet_cg);
            const randomeCgSubject = Math.floor(Math.random() * cg_subject.length)
    
            setSubject(sujet_cg[cg_subject[randomeCgSubject]])
        }
        // pour que ca affiche le "reserver au abo"
        else matiere = "ESH"
    }
    
    const handleRandom = (matiere) => {
        if (matiere === "HGG") 
        {
            const randomAnnee = `${Math.floor(Math.random() * 2) + 1}A`;
            const themes = Object.keys(sujet_hgg[randomAnnee])
            const randomTheme = Math.floor(Math.random() * themes.length)
            if (randomAnnee === "1A" || themes[randomTheme] === "Afrique" || themes[randomTheme] === "Moyen-Orient")
            {
                const subject = Object.keys(sujet_hgg[randomAnnee][themes[randomTheme]]);
                const randomIndex = Math.floor(Math.random() * subject.length);
                setSubject(sujet_hgg[randomAnnee][themes[randomTheme]][subject[randomIndex]]);
            }
            else 
            {
                const sousThemes = Object.keys(sujet_hgg[randomAnnee][themes[randomTheme]]);
                const randomSousThemes = Math.floor(Math.random() * sousThemes.length);
                const subject = Object.keys(sujet_hgg[randomAnnee][themes[randomTheme]][sousThemes[randomSousThemes]]);
                const randomIndex = Math.floor(Math.random() * subject.length);
                setSubject(sujet_hgg[randomAnnee][themes[randomTheme]][sousThemes[randomSousThemes]][subject[randomIndex]]);
            }
        }
        else if(matiere === "ESH")
        {
            const esh_themes = Object.keys(sujet_esh) 
            const randomEshTheme = Math.floor(Math.random() * esh_themes.length);
            const esh_subject = Object.keys(sujet_esh[esh_themes[randomEshTheme]]);
            const randomeEshSubject = Math.floor(Math.random() * esh_subject.length);

            setSubject(sujet_esh[esh_themes[randomEshTheme]][esh_subject[randomeEshSubject]]);
        }
    }
    return (
        <Fragment>
            {
                matiere === "HGG" ?(
                    <div className='sujet'>
                    {!isSub && <NotSub/>}
                    {
                        annee && (
                            <div onClick={() => setAnnee(null)} className='back'>
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                        )
                    }
                    {
                        surTheme && (
                            <div onClick={() => setSurTheme(null)} className='back'>
                                    <i className="fa-solid fa-arrow-left"></i>
                            </div>
                        )
                    }
                    {   
                        surTheme === "Continent européen" ? (
                            <div className='select-padding'>
                                <CustomSelect elementOptions={listeSujetEu} placeholder={"Continent européen"} sujet={true}/>
                            </div>
                        ): surTheme === "Continent américain" ? (
                            <div className='select-padding'>
                                <CustomSelect elementOptions={listeSujetAm} placeholder={"Continent américain"} sujet={true}/>
                            </div>
                        ): surTheme === "Continent asiatique" ? (
                            <div className='select-padding'>
                                <CustomSelect elementOptions={listeSujetAs} placeholder={"Continent asiatique"} sujet={true} />
                            </div>
                        ) : annee === "1" ? (
                            <div className='select-padding'>
                                <CustomSelect elementOptions={listeSujetHGG} placeholder={"Choisissez le thème"} sujet={true}/>
                            </div>
                        ): annee === "2" ?(
                                <div className='sur-container'>
                                    <div className='choice-btn-container-sujet'>
                                        <button onClick={() => {setSurTheme("Continent européen")}} className='choice-btn'>Continent européen</button>
                                        <button onClick={() => {setSurTheme("Continent américain")}} className='choice-btn'>Continent américain</button>
                                        <button onClick={() => {setSurTheme("Continent asiatique")}} className='choice-btn'>Continent asiatique</button>
                                    </div>
                                    <div className='choice-btn-container-sujet'>
                                        <button onClick={() => {setSelectSubject('Afrique')}} className='choice-btn'>Afrique</button>
                                        <button onClick={() => {setSelectSubject('Moyen-Orient')}} className='choice-btn'>Moyen-Orient</button>
                                    </div>
                                </div>
                        ):(
                            <div className='choice-btn-container-annee'>
                                <button onClick={() => setAnnee("1")} className='choice-btn'>1ère année</button>
                                <button onClick={() => setAnnee("2")} className='choice-btn'>2ème année</button>
                                <button onClick={() => handleRandom("HGG")} className='choice-btn'>Tirage aléatoire</button>
                            </div>
                        )}
                    </div>
                ) :  ( // si la matiere est ESH
                <Fragment>
                    {!isSub && <NotSub/>}
                    <div className='sujet-esh'>
                        <div className='select-padding'>
                            <CustomSelect elementOptions={listeSujetESH} placeholder={"Choisissez le thème"} sujet={true}/>
                        </div>
                        <p>Ou</p>
                        <button onClick={() => handleRandom("ESH")} className='choice-btn'>Tirage aléatoire</button>
                    </div>
                </Fragment>
                )
            }
        </Fragment>
      );
}
export default Sujet