import './app.scss'

import React, { useContext, useEffect} from 'react';
import { Routes, Route} from 'react-router-dom'

import { UserContext } from '../../3-context/0-user.context';
import { PaymentContext } from '../../3-context/2-payment.context';

import { getInfo, setInfo } from '../../4-utils/firebase.utils';

import Nav from '../1-nav/nav.component';
import Home from '../2-home/home.component'
import Analyse from '../3-analyse/analyse.component';
import Colles from '../4-colles/colles.component';
import Bibliotheque from '../5-bibliotheque/bibliotheque.component';
import Authentication from '../6-authentication/authentication.component';
import Vente from '../7-vente/vente.component';
import PDF from '../8-pdf/pdf.component';
import PaymentCheckout from '../9-payment-checkout/payment-checkout.component';
import PaymentStatus from '../10-payment-status/payment-status.component';
import Faq from '../11-faq/faq.component';
import MathComponent from '../12-math/math.component';

import { adresseGetCustomerId, adresseSubStatus } from '../../0-data/cloudAdress';

const App = () => {
  const { currentUser, setCurieux, curieux } = useContext(UserContext)
  const { setIsSub, isSub, setLoadingContent } = useContext(PaymentContext)

  // setLoadingContent(isSub === null ? true : false)

  useEffect(() => {
    setLoadingContent(true)
    const checkStatus = async () => {
      try {
        const curieuxStatus = await getInfo(currentUser.uid, "curieux")
        setCurieux(curieuxStatus)

        const customerId = await getInfo(currentUser.uid, 'customerId')
        if (!customerId) {
          const email = await getInfo(currentUser.uid, 'email')
          const requestBody = JSON.stringify({
            email: email,
          });
          const res = await fetch(adresseGetCustomerId, {
            method: "POST",
            body: requestBody,
            headers: {
            'Content-Type': 'application/json'
            }
          })

          const newCustomerId = await res.json()
          await setInfo(currentUser.uid, 'customerId', newCustomerId)
        }        
        if (customerId) {
          // recup status avonnement 
          const res = await fetch(adresseSubStatus , {
            method: "POST",
            body: JSON.stringify({customerId: customerId}),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          const data = await res.json();
          if (data.error) 
          {
            await setInfo(currentUser.uid, "abonne", false)
            setIsSub(false)
            sessionStorage.setItem('isSub', 'false')
            
            const nbCredit = await getInfo(currentUser.uid, "nombrePrompt")
            // on garde que le credit curieux
            if (nbCredit !== 1 && !curieux) 
            {
              await setInfo(currentUser.uid, 'nombrePrompt', 0)
            }
          }
          else if (data){
            await setInfo(currentUser.uid, "abonne", true)
            setIsSub(true)
            sessionStorage.setItem('isSub', 'true')
      
            const curieuxStatus = await getInfo(currentUser.uid, "curieux")
            setCurieux(curieuxStatus)

          }
          const isSpecial = await getInfo(currentUser.uid, 'abonne') 
          if (isSpecial === "special")
          {
            setIsSub(true)
            sessionStorage.setItem('isSub', 'true')
          }
          setLoadingContent(false)
        }
        else 
        {
          setLoadingContent(false)
          const isSpecial = await getInfo(currentUser.uid, 'abonne') 
          if (isSpecial === "special")
          {
            setIsSub(true)
            sessionStorage.setItem('isSub', 'true')
            
          }
        }
      } catch (error) {
        setLoadingContent(false)
        console.error('Erreur lors de la récupération du statut d\'abonnement:', error);
      }
    }
    if (currentUser) checkStatus() 
    else setLoadingContent(false)
  }, [currentUser]); 

  return (
   <Routes>
      <Route path='/' element={<Nav/>}>
        <Route index element={<Home/>}/>
        <Route path='/analyse' element={<Analyse/>}/>
        <Route path='/mesColles' element={<Colles source={"eleve"}/>}/>
        <Route path='/bibliotheque' element={<Bibliotheque/>}/>
        <Route path='/faq' element={<Faq/>} />
        <Route path='/authentication' element={<Authentication/>}/>
        <Route path='/vente' element={<Vente/>}/>
        <Route path='/pdf' element={<PDF/>}/>
        <Route path='/paymentCheckout' element={<PaymentCheckout/>}/>
        <Route path='/paymentStatus/:id' element={<PaymentStatus/>}/>
        <Route path='/math' element={<MathComponent/>}/>
      </Route>
   </Routes>
  );
}

export default App;