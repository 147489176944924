import './payment-checkout.styles.scss'

import { Fragment, useContext } from 'react';

import { loadStripe } from '@stripe/stripe-js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { PaymentContext } from '../../3-context/2-payment.context';

import Footer from '../../1-components/0-footer/footer.component';

import CGV from '../../0-data/CGV.pdf'
import { stripeKey } from '../../0-data/cloudAdress';

const stripePromise = loadStripe(stripeKey);

const PaymentCheckout = () => {
    const { options } = useContext(PaymentContext)
    return (
      <Fragment>
      {
        options ? (
          <Fragment>
            <div className="checkout">
              <div className='checkout-item'>
                <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              </div>
              <div className='disclaimer'>En passant votre commande, vous acceptez 
                les <a href={CGV} target='_blank' rel='noreferrer'> Conditions générales de vente</a> de MonColleur.fr 
              </div>
            </div>
          </Fragment>
        ): (
          <div className='checkout-error'>Une erreur s'est produite, merci de recommencer</div>
        )
      }
      <Footer/>

      </Fragment>
    )
}
export default PaymentCheckout