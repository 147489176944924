import '../pdf.styles.scss' 

import { Fragment } from 'react'


import ChartComponent from '../../../1-components/6-chart-component/chart-component.component'

const HggTemplate = () => {
    const requestContent = JSON.parse(sessionStorage.getItem('requestContent'))
    
    const {
        analyse_temps, 
        analyse_frequence, 
        frequence, 
        reponse, 
        temps, 
        transcription, 
        plan, 
        note
    }  =  requestContent?.data || requestContent || {};
    // le ? pour verifier que requestContent existe bien avant de voire le .data, si il existe pas alors tout sera = null 
    return (
        <Fragment>
            <ChartComponent 
                sujet={reponse["Sujet"]} 
                gram={reponse["Gram"]} 
                arg={reponse["Arg"]}
                arg2={reponse["Arg2"]}
                plan2={reponse["Plan2"]}
                ex2={reponse["Ex2"]}
            />
            <div><span className='title violet'>Note : <span className='note'>{note}</span></span></div>

            <div>
                <div className='title score'>Temps :</div>
                <span className='gros'>Tu as parlé {parseInt(temps/60)} minutes et {parseInt(Math.floor(temps % 60))} seconde(s). {analyse_temps}</span>
            </div>

            <div> 
                <span className='title score ecart'>Débit de parole :</span> <span className='gros'>Tu prononces en moyenne {parseInt(Math.floor(frequence))} mots par minutes. {analyse_frequence}</span>
            </div>

            <div className='cadre'>
                <div className='title'>Plan détaillé</div>
                <div className='content' dangerouslySetInnerHTML={{ __html: reponse["Detail"] }}></div>
            </div>

            <div className='cadre'>
                <div className='title'>Proposition de correction</div>
                <div className='content' dangerouslySetInnerHTML={{ __html: plan  }}></div>
            </div>


            <div className='section-title'>Commentaires généraux</div>
            <div className='cadre'>
                <div className='title'>Commentaire général</div>
                <div className='content'>{reponse["Gen"]}</div>
            </div>

            <div><span className='title score'>Score de traitement du sujet : </span> <span className='gros'>{reponse["Sujet"]}%</span></div>
            <div className='cadre'>
                <div className='title'>Points forts</div>
                <div className='content'>{reponse["Pos"]}</div>
            </div>

            <div className='cadre'>
                <div className='title'>Points à améliorer</div>
                <div className='content'>{reponse["Neg"]}</div>
            </div>

            <div className='cadre'>
                <div className='title'>Idées pertinentes non abordées</div>
                <div className='content'>{reponse["New"]}</div>
            </div>


            <div className='section-title'>Commentaires detaillés</div>
            <div className='cadre'>
                <div className='title'>Introduction</div>    
                <div className='content'>{reponse["Intro"]}</div>
            </div>

            <div className='cadre'>
                <div className='title'>Plan</div>
                <div className='content'>{reponse["Plan"]}</div>
            </div>

            <div> <span className='title score'>Score du plan :</span><span className='gros'>{reponse["Plan2"]}%</span></div>  
            <div><span className='title score ecart'>Démarche démonstrative : </span><span className='gros'>{reponse["Arg"]}%</span></div>  
            
            <div className='cadre'>
                <div className='title'>Analyse historique</div>
                <div className='content'> {reponse["Hist"]}</div>
            </div>

            <div className='cadre'>
                <div className='title'>Analyse géographique</div>
                <div className='content'> {reponse["Geo"]}{reponse["Eco"]}</div>
            </div>
                
            <div className='cadre'>
                <div className='title'>Erreur factuelles</div>
                <div className='content'> {reponse["Faits"]}</div>
            </div>

            <div className='cadre'>
                <div className='title'>Pertinence des arguments</div>
                <div className='content'> {reponse["Arg1"]} {reponse["Arg11"]}</div>
            </div>
            
            <div><span className='title score'>Score de pertinence :</span> <span className='gros'>{reponse["Arg2"]}</span>%</div>
            <div className='cadre'>
                <div className='title'>Richesse, qualité et diversité des exemples</div>
                <div className='content'>{reponse["Ex1"]}{reponse["Ex11"]}</div>    
            </div>
            <div><span className='title score'>Score des exemples :</span><span className='gros'>{reponse["Ex2"]}</span>%</div>


            <div className='section-title'>Langue et expression</div>
            <div className='cadre'>
                <div className='title'>Erreurs de langue</div>
                <div className='content'>{reponse["Lang"]}</div>
            </div>
            
            <div><span className='title score ecart'>Score de grammaire et de style :</span><span className='gros'>{reponse["Gram"]} %</span></div>
            
            <div className='section-title'>Transcription</div>
            <div className='cadre'>
                <div className='content'>{transcription}</div>
            </div>
        </Fragment>
    )
}
export default HggTemplate