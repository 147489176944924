import { createContext, useState } from "react";

export const BibliothequeContext = createContext({

    setBibliotheque: () => null, 
    bibliotheque: null,

})

export const BibliothequeProvider = ({children}) => {
    const [bibliotheque, setBibliotheque] = useState([])
    const value = { bibliotheque, setBibliotheque,}

    return <BibliothequeContext.Provider value={value}>{children}</BibliothequeContext.Provider>
}