import { useContext } from 'react'
import './not-sub.styles.scss'
import { Link } from 'react-router-dom'
import { RequestContext } from '../../3-context/4-request.context'


const NotSub = ({methode}) => {
    const { setSource, source } = useContext(RequestContext)
    const handleClick = () => {        
        setSource(null)
    }
    return (
        <div className={`not-sub-container ${source === 'bibliotheque' ? 'full' : source}` }>
            <div className={`content-container ${methode === 'colle'&& 'border'}`}>
                <div className='content'>Cette fonctionnalité est réservée aux abonnés !</div>
                <Link to={"../vente"} className='btn' onClick={handleClick}>S'abonner</Link>
                {source === 'bibliotheque' && 
                    <Link to={'../bibliotheque'} className='not-sub-back' onClick={handleClick}>
                        <i className="fa-solid fa-xmark"></i>
                    </Link>
                }
            </div>
        </div>
    )
}
export default NotSub