import './prompt.styles.scss'
import './prompt.responsive.scss'

import { Fragment, useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { UserContext } from '../../3-context/0-user.context';
import { AudioContext } from '../../3-context/6-audio.context';
import { RequestContext } from '../../3-context/4-request.context'
import { AnalyseContext } from '../../3-context/3-analyse.context'
import { PaymentContext } from '../../3-context/2-payment.context';

import { getInfo, saveJson, updateCredits } from '../../4-utils/firebase.utils';

import AudioPicker from '../9-audio-picker/audio-picker.component';

async function fetchDataAndGeneratePdf(formData, fetchUrl) {
    try {
      const response = await fetch(fetchUrl, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
}
const Prompt = ({subject, methode}) => {
    const navigate = useNavigate()

    const { currentUser, nombrePrompt, setNombrePrompt} = useContext(UserContext);
    const { isSub } = useContext(PaymentContext)
    const { fetchUrl, setFetchUrl, matiere } = useContext(AnalyseContext)
    const { setRequestState } = useContext(RequestContext)
    const { audioFile, audioDuration} = useContext(AudioContext);
    
    const [promptSubject, setPromptSubject] = useState(subject)

    const actualiserNbRequest = async () => {
        if(currentUser && !isSub) 
        {
            const currentNombrePrompt = await getInfo(currentUser.uid, 'nombrePrompt');
            setNombrePrompt(currentNombrePrompt); 
        }
    }
    actualiserNbRequest()
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (methode === "colle" && !audioFile)
        {
            document.querySelector('.etat').classList.add('red')
        }
        else 
        {
            try {
                const formData = new FormData();
                if(methode === "colle")
                {
                    formData.append('audio', audioFile);
                    formData.append('audioDuration', audioDuration)
                    formData.append('nom_fichier', audioFile.name)
                }
                formData.append('methode', methode)
                formData.append('subject', promptSubject);

                setRequestState('loading')
                
                const data = await fetchDataAndGeneratePdf(formData, fetchUrl);

                const colle = await saveJson(data, promptSubject, currentUser.uid, matiere, methode);
                sessionStorage.setItem('requestContent', JSON.stringify(colle))
                // dans la bdd
                setRequestState('finished')

                await updateCredits(currentUser, methode)
                // dans le site 
                actualiserNbRequest(currentUser)
                
               
                
                setFetchUrl(null)
            } catch (error) {
                setRequestState('error')
                console.error(error);
            }
        }
    };
    return (
        <div>
            {currentUser ? (
                <Fragment>
                    <div className='prompt-title absolute'>{matiere}</div>
                        <form className="form absolute" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                            <input 
                                placeholder='Titre du sujet de colle'
                                className='input'
                                type="text" 
                                name="subject" 
                                value={promptSubject}
                                onChange={(e) => setPromptSubject(e.target.value)}
                                required
                            />
                            {methode === "colle" ? (
                                <Fragment>
                                    <AudioPicker />
                                    {
                                        audioDuration > 650 || (!isSub && nombrePrompt < 1) ? (
                                            <Fragment>
                                                <div className='submit-btn disabled' type="submit"> Lancer l'évaluation </div>
                                                {audioDuration > 650 && (
                                                    <div className='nombrePrompt-error'>Ta colle semble durer bien plus que 10 minutes...</div> 
                                                )}
                                                {!isSub && nombrePrompt < 1 &&(
                                                    <div className='nombrePrompt-error'>Vous n'avez plus de crédits disponibles pour des colles</div> 
                                                )}
                                            </Fragment>
                                        ): (
                                            <button className='submit-btn' type="submit"> Lancer l'évaluation</button>
                                        )
                                    }
                                </Fragment>
                            ): (
                                <Fragment>
                                    {!isSub && nombrePrompt === 0 ? (
                                        <Fragment>
                                            <div className='credit-error'>Vous n'avez plus de crédits disponibles</div>
                                            <div className='submit-btn btn-special disabled' >Obtenir un plan détaillé</div> 
                                        </Fragment>
                                    ): (
                                        <button className='submit-btn' type="submit">Obtenir un plan détaillé</button>
                                    )}
                                </Fragment>

                            )
                            }
                        </form>
                    {!isSub && <div className='credit'>Nombre de crédits restants : {nombrePrompt}</div>}
                    
                    {
                        !isSub && nombrePrompt === 0 && (
                            <Link className='sub-btn' to={'/vente'}>S'abonner</Link>
                        )
                    }
                </Fragment>
            ): (
                <div className='not-connected-prompt'>
                    <p> Merci de vous connecter</p>
                    <button onClick={() => navigate('../authentication')}>Se connecter</button>
                </div>
            )}
        </div>
    );
};

export default Prompt;