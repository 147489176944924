import './pdf.styles.scss'
import './pdf.responsive.scss'
import { Fragment, useContext, useEffect, useRef, useState } from 'react';

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { AnalyseContext } from '../../3-context/3-analyse.context';
import { RequestContext } from '../../3-context/4-request.context';

import HggTemplate from './templates/hgg.pdf'
import EshTemplate from './templates/esh.pdf'
import CgTemplate from './templates/cg.pdf'

import NotSub from '../../1-components/10-not-sub/not-sub.component';
import { PaymentContext } from '../../3-context/2-payment.context';

const PDF = () => {
    const pdfRef = useRef()
    const { isSub } = useContext(PaymentContext)
    const { matiere } = useContext(AnalyseContext)
    const { source } = useContext(RequestContext)

    const requestContent = JSON.parse(sessionStorage.getItem('requestContent'))
        
    const {user_subject, plan} = requestContent?.data || requestContent || {};
    const { methode } = requestContent
    // le ? pour verifier que requestContent existe bien avant de voire le .data, si il existe pas alors tout sera = null 

    let matiereData = requestContent.matiere || matiere
    
    const [isDownloadClicked, setIsDownloadClicked] = useState(false);

    if (!isSub && source === 'bibliotheque') document.body.style.overflow = 'hidden' 
    else document.body.style.overflow = 'auto'

    const downloadPDF = () => {
        setIsDownloadClicked(true);
        const input = pdfRef.current;
        const scale = 2; 
        html2canvas(input, { scale: scale }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            const pdfWidth = canvas.width / scale;
            const pdfHeight = canvas.height / scale;
            const pdf = new jsPDF('p', 'px', [pdfWidth, pdfHeight], true);
            const imgX = 0;
            const imgY = 0;
            pdf.addImage(imgData, 'JPEG', imgX, imgY, pdfWidth, pdfHeight);
            pdf.save(`${user_subject}.pdf`);
        });
    };

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <Fragment>
            {
                !requestContent ? (
                    <div className='no-data'>Pas de données à afficher, rendez vous dans "Mes Colles"</div>
                ) : (
                    <Fragment>
                        {!isDownloadClicked && (
                            <div onClick={downloadPDF} className='download-btn'>
                                <i className="fa-solid fa-download "></i>
                            </div>
                        )}
                        {
                            !isSub && source === 'bibliotheque' &&(
                                <Fragment>
                                    <NotSub source={source} methode={methode}/>
                                </Fragment>
                            )                        
                        }
                        <div className={`pdf ${isDownloadClicked ? 'downloaded' : ''}`} ref={pdfRef}>
                                <div className='user_subject'>{user_subject}</div>
                                {
                                    (!methode || methode === "colle") ? (
                                        <Fragment>
                                            {matiereData === "HGG" && <HggTemplate/>}
                                            {matiereData === "ESH" && <EshTemplate/>}
                                            {matiereData === "CG" && <CgTemplate/>}
                                        </Fragment>
                                    ) : (
                                        <div className='cadre special'>
                                            <div className='title'>Proposition de correction</div>
                                            <p className='disclaimer'>La proposition de correction doit vous aider dans votre réflexion et votre démarche de 
                                            problématisation, mais ne saurait les remplacer.</p>
                                            <div className='content' dangerouslySetInnerHTML={{ __html: plan  }}></div>
                                        </div>
                                    )
                                }
                        </div>
                    </Fragment>
                )
            }
        </Fragment>
    )
}
export default PDF